import React from "react";
import { Col, Card, Row, Container } from "react-bootstrap";

import "./ServicesDetails.css";
import myImage1 from "./Images/webdesign.jpeg";
import myImage2 from "./Images/webdev.jpeg";
import myImage3 from "./Images/appdev.jpeg";
import myImage4 from "./Images/analitics.jpeg";
import myImage5 from "./Images/application.jpeg";
import myImage6 from "./Images/strategy.jpeg";
import myImage7 from "./Images/digitalmark.jpeg";
import myImage8 from "./Images/servicecreat.png";

const ServicesDetail = () => {
  return (
    <>
      <div className="containers">
        <Container>
          <h2 className="d-flex justify-content-center mb-4">
            Services Details
          </h2>

          <Row className="d-flex justify-content-center ">
            <Col xs={12} md={3}>
              <Card
                className="service-cards"
                style={{ border: "2px solid  #2196f3 " }}
              >
                <Card.Body>
                  <Card.Title className="card-title">
                    <img src={myImage1} alt="My Image" />
                    <br /> Web Design
                  </Card.Title>
                  <Card.Text>
                    Custom web development solutions to elevate your online
                    presence.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={3}>
              <Card
                className="service-cards"
                style={{ border: "2px solid #2196f3 " }}
              >
                <Card.Body>
                  <Card.Title className="card-title">
                    <img src={myImage2} alt="My Image" />
                    <br /> Web Development
                  </Card.Title>
                  <Card.Text>
                    Custom web development solutions to elevate your online
                    presence.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={3}>
              <Card
                className="service-cards"
                style={{ border: "2px solid #2196f3 " }}
              >
                <Card.Body>
                  <Card.Title className="card-title">
                    <img src={myImage3} alt="My Image" />
                    <br />
                    App Development
                  </Card.Title>
                  <Card.Text>
                    Custom app development solutions to elevate your online
                    presence.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={3}>
              <Card
                className="service-cards"
                style={{ border: "2px solid #2196f3 " }}
              >
                <Card.Body>
                  <Card.Title className="card-title">
                    <img src={myImage4} alt="My Image" /> <br />
                    Analitics
                  </Card.Title>
                  <Card.Text>
                    Custom web development solutions to elevate your online
                    presence.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center mb-4">
            <Col xs={12} md={3}>
              <Card
                className="service-cards"
                style={{ border: "2px solid #2196f3 " }}
              >
                <Card.Body>
                  <Card.Title className="card-title">
                    <img src={myImage5} alt="My Image" /> <br />
                    Application
                  </Card.Title>
                  <Card.Text>
                    Cutting-edge app development for various platforms.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={3}>
              <Card
                className="service-cards"
                style={{ border: "2px solid #2196f3 " }}
              >
                <Card.Body>
                  <Card.Title className="card-title">
                    <img src={myImage6} alt="My Image" /> <br /> Strategy
                  </Card.Title>
                  <Card.Text>
                    Cutting-edge app development for various platforms.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={3}>
              <Card
                className="service-cards"
                style={{ border: "2px solid #2196f3 " }}
              >
                <Card.Body>
                  <Card.Title className="card-title">
                    <img src={myImage7} alt="My Image" /> <br />
                    Digital Marketing
                  </Card.Title>
                  <Card.Text>
                    Cutting-edge app development for various platforms.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={3}>
              <Card
                className="service-cards"
                style={{ border: "2px solid #2196f3 " }}
              >
                <Card.Body>
                  <Card.Title className="card-title">
                    <img src={myImage8} alt="My Image" /> <br />
                    Creative Service
                  </Card.Title>
                  <Card.Text>
                    Cutting-edge app development for various platforms.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ServicesDetail;
