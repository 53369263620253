import React from "react";
import "./style.css";
import { Col, Row } from "antd";
import { Layout } from "antd";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faPhone,faGlobe,faEnvelope,faLocationDot} from "@fortawesome/free-solid-svg-icons";
import { faTwitter, faFacebook ,faInstagram ,faLinkedin } from '@fortawesome/free-brands-svg-icons';
const { Footer } = Layout;

const FooterPart = () => {
  return (
    <>
      <Footer className="footer-part">
        <div className="footer-part-deco"></div>
        <div className="container">
          <Row className="d-flex justify-content-center">
            <Col lg={8} md={24} sm={24} xs={24}>
              <h4 className="footer-heading">NeuroWebGravity</h4>
              <h5 className="mt-3">About Us</h5>
              <p className="mt-3 footer-paragraph">
                Lorem ipsum dolor sit amet consectetur adipisicing<br></br>{" "}
                elit. Laudantium distinctio iste dolorum ipsam<br></br>{" "}
                perspiciatis quis, repellendus accusamus quasi<br></br> labore
                ea.
              </p>
            </Col>
            <Col lg={4} md={12} sm={12} xs={24}>
              <h5>Legal</h5>
              <div className="mt-4">
                <p className="footer-paragraph">Terms & Conditions</p>
                <p className="footer-paragraph">Privacy Policy</p>
              </div>
            </Col>

            <Col lg={4} md={12} sm={12} xs={24}>
              <h5>Get In Touch</h5>
              <div className="mt-4">
                <p className="footer-paragraph">Sign Up Free</p>
                <p className="footer-paragraph">Sign In</p>
                <p className="footer-paragraph">Support</p>
                <p className="footer-paragraph">Contact Us</p>
              </div>
            </Col>

            <Col lg={4} md={12} sm={12} xs={24}>
              <h5>Quick Links</h5>
              <div className="mt-4">
                <p className="footer-paragraph">Home</p>
                <p className="footer-paragraph">About</p>
                <p className="footer-paragraph">Contact</p>
                <p className="footer-paragraph">ServicesListing</p>
                <p className="footer-paragraph">ServicesCategory</p>
                <p className="footer-paragraph">ServicesDetail</p>
              </div>
            </Col>

            <Col lg={4} md={12} sm={12} xs={24}>
              <h5 class="">Contact Us</h5>
              <ul class="list-inline footer-contact mt-4">
                <li className="mb-3">
                  <Link href="#">
                    <FontAwesomeIcon icon={faPhone} className="footer-icon" />
                    1234567899
                  </Link>
                </li>
                <li className="mb-3">
                  <Link href="#">
                    <FontAwesomeIcon icon={faGlobe} className="footer-icon" />
                    gravitonwebtechnology.com
                  </Link>
                </li>
                <li className="mb-3">
                  <Link href="#">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="footer-icon"
                    />
                    email@.com
                  </Link>
                </li>
                <li className="mb-3">
                  <Link href="#"><FontAwesomeIcon
                      icon={faLocationDot}
                      className="footer-icon"
                    />
                    Address
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>
          
          <Row className="mt-5 text-center">
           <hr class="my-3 w-100" color="white"></hr>
            <Col lg={12} md={24}>
            <div class="social-link">
             <Link href="#"><FontAwesomeIcon icon={faFacebook} className="social-icon"/></Link>
             <Link href="#"><FontAwesomeIcon icon={faInstagram} className="social-icon"/></Link>
             <Link href="#"><FontAwesomeIcon icon={faTwitter} className="social-icon"/></Link>
             <Link href="#"><FontAwesomeIcon icon={faLinkedin} className="social-icon"/></Link>
            </div>
            </Col>

            <Col lg={12} md={24}>
            <p className="footer-paragraph">Copyright@2020 All Rights Reserved By :
              <Link href="#" className="footer-text" >
                  <strong> Gravitonweb Technologies</strong>
              </Link></p>
            </Col>
          </Row>
        </div>
      </Footer>
    </>
  );
};

export default FooterPart;
