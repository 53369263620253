import React from 'react'
import './ServicesListing.css';
import { Container, Row, Col } from "react-bootstrap";

const ServicesListing = () => {
  return (   

    <>
       <div>
        <div className="section">
          <div className="banner">
            <div className="banner-content">
              <div className="heading">
                <h1>Grow Your Business</h1>
              </div>
              {/* Other content */}
            </div>
          </div>
          <h2 className="text-center" >
                Technical Development
              </h2>
          <Container>
            <Row className="align-items-center">
              <Col
                xs={12}
                md={6}
                className="order-md-2 text-center text-md-right"
              >
                <img
                  src="./Images/userfriendly.png"
                  alt="Your Image"
                  className="img-fluid"
                />
              </Col>
              <Col xs={12} md={6} className="order-md-1">
                <div className="content">
                  <h5 className="heading2">User-friendly Website:</h5>

                  <p className="menu">
                    Create captivating websites that engage visitors and drive
                    results.{" "}
                  </p>
                  <ul className="menu">
                    <li> Clear and intuitive navigation </li>
                    <li>Mobile responsiveness</li>
                    <li>Fast loading speed</li>
                    <li>Engaging and relevant content </li>
                    <li>Strong calls to action (CTAs) </li>
                    <li>Contact information and forms </li>
                    <li>Testimonials and social proof </li>
                    <li>Visual appeal </li>
                  </ul>
                  <p className="menu">
                    “Transform your online presence into a profit-generating
                    client magnet with a clean and simple website design that
                    effortlessly captures attention, invites exploration, and
                    maximizes your bottom line.”{" "}
                  </p>
                  <p className="menu">Online Payment Integration: </p>
                  <p className="menu">
                    Seamlessly integrate online payment systems for hassle-free
                    transactions.{" "}
                  </p>
                  <ul className="menu">
                    <li>Offer multiple payment options for convenience</li>
                    <li>Prioritize secure payment processing.</li>
                    <li>Streamline the checkout process for ease of use.</li>
                    <li>Optimize for mobile devices. </li>
                    <li>Maintain transparency in pricing and policies.</li>
                    <li>Provide immediate order confirmation and receipts.</li>
                    <li>Integrate with CRM for better customer management</li>
                    <li>Ensure PCI compliance and data security.</li>
                  </ul>
                  <p className="menu">
                    “Embrace the simplicity and convenience of seamless online
                    payments, captivating clients with a clean and hassle-free
                    experience that fuels their motivation and trust in your
                    business.”{" "}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>

          <Container>
            <Row className="align-items-center">
              <Col md={6}>
                <img
                  className="img-responsive"
                  src="./Images/android.png"
                  alt="Your Image"
                />
              </Col>
              <Col md={6}>
                <div className="content">
                  <h5 className="heading2"> Android App Development: :</h5>

                  <p className="menu">
                    Enhance your business’s mobile presence with engaging
                    Android apps.
                  </p>
                  <ul className="menu">
                    <li>Vast user base and market reach.</li>
                    <li>
                      Increased customer engagement and personalized
                      interactions.
                    </li>
                    <li>Enhanced brand visibility and recognition.</li>
                    <li>
                      Potential for revenue generation through various
                      monetization strategies.
                    </li>
                    <li>
                      Seamless user experience leveraging native Android
                      features.
                    </li>
                    <li>Offline accessibility for uninterrupted usage.</li>
                    <li>
                      Valuable data analytics and insights for informed
                      decision-making.
                    </li>
                    <li>
                      Competitive advantage and differentiation from
                      competitors.
                    </li>
                    <li>Scalability and adaptability for future growth.</li>
                    <li>
                      Access to the extensive Android ecosystem and developer
                      support.
                    </li>
                  </ul>
                  <p className="menu">
                    “Experience the transformative power of Android app
                    development, as it combines cost leadership, captivating
                    visuals, FOMO-inducing effects, precise eye-tracking, and
                    decision clarity to create a simple and clean experience
                    that captivates your audience, converts prospects, and
                    unlocks your business’s full potential.”{" "}
                  </p>
                  {/* Rest of the content */}
                </div>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col md={6}>
              <div className="content">
                <h5 className="heading2"> Chat Support: </h5>

                <p className="menu">
                  Provide responsive chat support for instant assistance and
                  customer satisfaction.
                </p>
                <ul className="menu">
                  <li> Instant and real-time assistance.</li>
                  <li>24/7 availability for customer support.</li>
                  <li> Personalized interactions and tailored solutions.</li>
                  <li>Convenient multi-channel access.</li>
                  <li>Efficient issue resolution.</li>
                  <li>Cost-saving compared to traditional support channels.</li>
                  <li>
                    Proactive customer engagement and upselling opportunities.
                  </li>
                  <li>Improved customer satisfaction and retention</li>
                  <li>
                    Real-time data and insights for informed decision-making.
                  </li>
                  <li>
                    Integration with CRM systems for efficient customer
                    management.
                  </li>
                </ul>
                <p className="menu">
                  “Elevate your business with the power of chat support,
                  seamlessly integrated with cost-effective strategies,
                  captivating visuals, irresistible FOMO effects, precise
                  eye-tracking, and simplified decision-making. Watch as your
                  website captivates, converts, and unlocks untapped business
                  potential, leaving clients motivated, engaged, and ready to
                  take the next step.”
                </p>
                </div>
              </Col>
              <Col md={6}>
                <img className="img-responsive" src="./Images/chat.jpg" />
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col md={6}>
                <img className="img-responsive" src="./Images/domain.png" />
              </Col>
              <Col md={6}>
              <div className="content">
                <h5 className="heading2"> Domain Name (.com): </h5>

                <p className="menu">
                  Register domain names (.com) for a professional online
                  identity.
                </p>
                <ul className="menu">
                  <li> Professional brand image.</li>
                  <li>Global recognition.</li>
                  <li> SEO advantage.</li>
                  <li>Brand protection.</li>
                  <li>Customer trust.</li>
                  <li> Enhanced brand recall.</li>
                  <li>Investment value.</li>
                  <li>Email credibility.</li>
                  <li>Brand consistency.</li>
                  <li>Future expansion.</li>
                </ul>
                <p className="menu">
                  “Unlock your business’s full potential with a .com domain name
                  that combines cost leadership, captivating visuals,
                  FOMO-inducing effects, precise eye-tracking, and clear
                  decision-making. Experience a website that captivates,
                  converts, and drives your business forward, fueling motivation
                  and success along the way.”
                </p>
                </div>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col md={6}>
              <div className="content">
                <h5 className="heading2">Reliable Hosting: </h5>

                <p className="menu">
                  Ensure uninterrupted website performance with reliable hosting
                  services
                </p>
                <ul className="menu">
                  <li>
                    {" "}
                    Maximum uptime for uninterrupted website availability.{" "}
                  </li>
                  <li>Fast loading speeds for an enhanced user experience.</li>
                  <li>
                    {" "}
                    Scalability to accommodate business growth and fluctuating
                    traffic.
                  </li>
                  <li>
                    Robust security measures to protect website and customer
                    data.
                  </li>
                  <li>
                    Seamless user experience leveraging native Android features.
                  </li>
                  <li>
                    {" "}
                    Dedicated technical support for prompt issue resolution.{" "}
                  </li>
                  <li>Regular backups and data recovery options.</li>
                  <li>SEO benefits for better search engine rankings.</li>
                  <li> Customization and control over website settings. </li>
                  <li>Enhanced customer trust and confidence.</li>
                  <li>Cost-effective solution for long-term reliability. </li>
                </ul>
                <p className="menu">
                  “Fuel your business ambitions with reliable hosting, merging
                  the power of cost leadership, captivating visuals,
                  FOMO-inducing effects, eye-tracking precision, and clear
                  decision-making. Watch as your website captivates, converts,
                  and maximizes its potential, igniting motivation and
                  propelling your business forward.”
                </p>
                </div>
              </Col>
              <Col md={6}>
                <img
                  className="img-responsive"
                  src="./Images/RealHositing.png"
                />
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col md={6}>
              <div className="content">
                <h5 className="heading2">Tracking and Analytics: </h5>

                <p className="menu">
                  Track and analyze visitor rates to optimize your online
                  presence.
                </p>
                <ul className="menu">
                  <li>
                    {" "}
                    Maximum uptime for uninterrupted website availability.{" "}
                  </li>
                  <li>Fast loading speeds for an enhanced user experience.</li>
                  <li>
                    {" "}
                    Scalability to accommodate business growth and fluctuating
                    traffic.
                  </li>
                  <li>
                    Robust security measures to protect website and customer
                    data.
                  </li>
                  <li>
                    Seamless user experience leveraging native Android features.
                  </li>
                  <li>
                    {" "}
                    Dedicated technical support for prompt issue resolution.{" "}
                  </li>
                  <li>Regular backups and data recovery options.</li>
                  <li>SEO benefits for better search engine rankings.</li>
                  <li> Customization and control over website settings. </li>
                  <li>Enhanced customer trust and confidence.</li>
                  <li>Cost-effective solution for long-term reliability. </li>
                </ul>
                <p className="menu">
                  “Fuel your business ambitions with reliable hosting, merging
                  the power of cost leadership, captivating visuals,
                  FOMO-inducing effects, eye-tracking precision, and clear
                  decision-making. Watch as your website captivates, converts,
                  and maximizes its potential, igniting motivation and
                  propelling your business forward.”
                </p>
                </div>
              </Col>
              <Col md={6}>
                <img
                  className="img-responsive"
                  src="./Images/tracking.svg"
                  // style={{ marginTop: "-25px" }}
                />
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col md={6}>
                <img className="img-responsive" src="./Images/bussiness1.png" alt=" "/>
              </Col>
              <Col md={6}>
              <div className="content">
                <h5 className="heading2">Business Development: </h5>

                <p className="menu">Google Page Listing:</p>
                <p className="menu">
                  Boost your online visibility and attract more customers with a
                  Google page listing.
                </p>
                <ul className="menu">
                  <li>
                    {" "}
                    Increased online visibility in relevant search results..{" "}
                  </li>
                  <li>
                    Trust and credibility through customer reviews and ratings.
                  </li>
                  <li>
                    {" "}
                    Local search advantage for reaching nearby customers.
                  </li>
                  <li>Convenient access to important business information.</li>
                  <li>
                    Mobile-friendly presentation for optimal user experience.
                  </li>
                  <li>
                    {" "}
                    Valuable insights and analytics for data-driven decisions.{" "}
                  </li>
                  <li>Integration with Google Maps for easy navigation.</li>
                  <li>Competitive advantage in search rankings.</li>
                  <li> Cost-effective marketing with wide audience reach. </li>
                  <li>
                    Enhanced customer engagement and conversion potential.
                  </li>
                </ul>
                <p className="menu">
                  “Supercharge your business with a Google Page Listing that
                  unlocks success. Embrace the power of cost leadership,
                  captivating visuals, and confident decision-making. Experience
                  a website that captivates, converts, and maximizes your
                  business potential. Let the motivation to excel in the digital
                  realm drive your success, powered by the simplicity and
                  effectiveness of a Google Page Listing.”
                </p>
                </div>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col md={6}>
              <div className="content">
                <h6 className="heading2">CRM Software: </h6>

                <p className="menu">
                  Streamline sales management and customer relationships with
                  CRM software.
                </p>
                <ul className="menu">
                  <li> Streamlined customer management and organization. </li>
                  <li>
                    Enhanced customer relationships through personalized
                    engagement.
                  </li>
                  <li>
                    Efficient sales and lead management for increased
                    conversions.
                  </li>
                  <li>Improved customer service and satisfaction.</li>
                  <li>
                    Marketing automation for targeted campaigns and lead
                    nurturing.
                  </li>
                  <li>Data-driven insights for informed decision making. </li>
                  <li>
                    Seamless collaboration and communication across teams.
                  </li>
                  <li>
                    Scalable and customizable to meet specific business needs.
                  </li>
                  <li>
                    Integration with other business systems for streamlined
                    workflows.{" "}
                  </li>
                  <li>
                    Cost and time savings through automation and optimization.
                  </li>
                </ul>
                <p className="menu">
                  “Empower your business with CRM software and unlock the
                  potential of cost leadership, captivating visuals, confident
                  decision-making, and customer-centricity. Experience a
                  streamlined website that drives conversions and maximizes
                  growth. Feel motivated to nurture relationships, boost sales,
                  and achieve success with the simplicity and effectiveness of
                  CRM software.”
                </p>
                </div>
              </Col>
              <Col md={6}>
                <img className="img-responsive" src="./Images/crm.png" />
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col md={6}>
                <img className="img-reponsive" src="./Images/legel.png" />
              </Col>
              <Col md={6}>
              <div className="content">
                <h5 className="heading2">Legal Compliance: </h5>

                <p className="menu">
                  Receive support in ensuring your business operates within
                  legal guidelines.
                </p>

                <ul className="menu">
                  <li> Risk mitigation and avoidance of legal disputes. </li>
                  <li>Protection against liability and legal challenges.</li>
                  <li>
                    {" "}
                    Promotion of ethical business practices and transparency.
                  </li>
                  <li>
                    Ensuring employee well-being and compliance with labor laws.{" "}
                  </li>
                  <li>
                    Data privacy and security for customer and employee
                    information.
                  </li>
                  <li>
                    {" "}
                    Competitive advantage through trust and responsible
                    operations.{" "}
                  </li>
                  <li>
                    Adherence to industry-specific regulations and standards.
                  </li>
                  <li>
                    Resilience and business continuity in the face of legal
                    changes
                  </li>
                  <li> Building trust and confidence among stakeholders. </li>
                  <li>
                    Adaptability to evolving legal requirements and landscape.
                  </li>
                </ul>
                <p className="menu">
                  “Embrace legal compliance as you pursue cost-effective
                  strategies, captivating visuals, and confident
                  decision-making. Feel motivated to create a website that
                  drives conversions and maximizes business potential while
                  staying within the boundaries of the law. Let the power of
                  this approach guide your success, ensuring trust, risk
                  mitigation, and sustainable growth.”
                </p>
                </div>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col md={6}>
              <div className="content">
                <h5 className="heading2">Current Bank Account: </h5>

                <p className="menu">
                  Set up a current bank account in your company’s name for
                  smooth financial transactions.
                </p>

                <ul className="menu">
                  <li>Convenient transactional capabilities</li>
                  <li>Accessible online banking services.</li>
                  <li> Check writing facilities.</li>
                  <li>Debit card access for easy payments. </li>
                  <li>Overdraft facility for managing cash flow.</li>
                  <li> Account management tools for tracking finances. </li>
                  <li>Integration with business operations.</li>
                  <li>Dedicated business support services.</li>
                  <li>Access to financing options. </li>
                  <li>Enhanced safety and security.</li>
                </ul>
                <p className="menu">
                  “Unlock the power of a current bank account as you prioritize
                  cost leadership, confident decision-making, and captivating
                  visuals. Experience the motivation to create a website that
                  maximizes your business potential, supported by the
                  convenience and security of a current bank account. Let this
                  mindset drive your success, ensuring financial stability, a
                  competitive advantage, and accelerated business growth with
                  peace of mind.”
                </p>
                </div>
              </Col>
              <Col md={6}>
                <img className="img-responsive" src="./Images/bank.png" />
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col md={6}>
              <div className="content">
                <h5 className="heading2">Nearby Branding Product: </h5>

                <p className="menu">
                  Get recommendations for branding products to enhance your
                  local presence.
                </p>

                <ul className="menu">
                  <li> Localized branding for a strong local presence. </li>
                  <li>Increased visibility among the local customer base.</li>
                  <li>
                    {" "}
                    Community engagement to foster connection and loyalty.
                  </li>
                  <li>Targeted marketing for effective campaigns. </li>
                  <li>Competitive advantage over non-local competitors.</li>
                  <li> Brand recognition and recall. </li>
                  <li>
                    Word-of-mouth referrals from satisfied local customers.
                  </li>
                  <li>Cost-effective marketing solutions.</li>
                  <li>Support for the local economy. </li>
                  <li>Authenticity and trust-building with local customers.</li>
                </ul>
                <p className="menu">
                  “Experience the transformative impact of nearby branding
                  products, fueling your motivation to create a captivating
                  website that maximizes your business potential. Embrace cost
                  leadership, captivating visuals, and a strategic mindset to
                  establish a strong local presence, build brand loyalty, and
                  unlock growth opportunities. Let this mindset propel your
                  business forward, setting the stage for success and
                  prosperity.”
                </p>
                </div>
              </Col>
              <Col md={6}>
                <img className="img-responsive" src="./Images/near.jpg" />
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col md={6}>
                <img className="img-responsive" src="./Images/report.png" />
              </Col>
              <Col md={6}>
              <div className="content">
                <h5 className="heading2">Analytics and Reporting </h5>

                <p className="menu">
                  Measure and analyze your business’s performance with detailed
                  analytics and reporting.
                </p>

                <ul className="menu">
                  <li> Data-driven decision making. </li>
                  <li>Performance tracking and evaluation.</li>
                  <li>Identifying growth opportunities and market trends.</li>
                  <li>Real-time monitoring for timely actions. </li>
                  <li>Goal setting and measurement.</li>
                  <li> Customer insights and personalized marketing. </li>
                  <li>Cost optimization and resource allocation.</li>
                  <li>Competitive advantage through data-driven strategies.</li>
                  <li>Forecasting and predictive analytics. </li>
                  <li>
                    Customization and scalability for evolving business needs.
                  </li>
                </ul>
                <p className="menu">
                  “Supercharge your business with analytics and reporting. Adopt
                  a strategic mindset that leverages cost leadership,
                  captivating visuals, FOMO-inducing effects, precise
                  eye-tracking, and informed decision-making. Experience the
                  motivation as you create a website that captivates, converts,
                  and maximizes your business potential. Let data-driven
                  insights guide your strategies, outperform competitors, and
                  achieve outstanding success. Unlock the full power of your
                  business with this winning mindset, driving growth,
                  profitability, and a competitive edge in the market.”
                </p>
                </div>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col md={6}><div className="content">
              <h5 className="heading2">Complete Support: </h5>

                <p className="menu">Enjoy comprehensive support from our dedicated team.</p>

                <ul className="menu">
                  <li> Dedicated customer support. </li>
                  <li>Expert technical assistance.</li>
                  <li>Comprehensive training and onboarding.</li>
                  <li>Proactive monitoring and maintenance. </li>
                  <li>Timely updates and upgrades.</li>
                  <li>Customized solutions. </li>
                  <li>24/7 availability.</li>
                  <li>Efficient escalation management.</li>
                  <li>Knowledge base and self-service resources. </li>
                  <li>Continuous improvement. .</li>
                </ul>
                <p className="menu">
                  “Boost your business with complete support. Harness the power
                  of effective strategies, captivating visuals, and informed
                  decision-making. Experience the transformation as your website
                  captivates and converts, maximizing your potential. Our
                  dedicated support team is here to assist you every step of the
                  way. Take your business to new heights with our comprehensive
                  support solutions.”</p>
                </div>
              </Col>
              <Col md={6}>
                <img
                  className="img-responsive"
                  src="./Images/support.png"
                  // style={{ marginTop: "-14px" }}
                />
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col md={6}>
                <img className="img-responsive" src="./Images/digital.png" />
              </Col>
              <Col md={6}>
              <div className="content">
                <h3>Marketing Development: </h3>
                <h5 className="heading2"> Digital Marketing: </h5>

                <p className="menu">
                  Skyrocket your online presence with digital marketing services
                  (one-month free trial).
                </p>

                <ul className="menu">
                  <li>Increased online visibility </li>
                  <li>Targeted audience reach</li>
                  <li>Cost-effective advertising</li>
                  <li>Measurable results </li>
                  <li>Enhanced brand awareness</li>
                  <li>Engaging content marketing </li>
                  <li>Social media engagement</li>
                  <li>Lead generation and conversion</li>
                  <li>Competitive edge </li>
                </ul>
                <p className="menu">
                  “Experience the power of digital marketing to drive your
                  business forward. Embrace cost-effective strategies,
                  captivating visuals, and data-driven decision-making. Stand
                  out from the competition and inspire action with a sense of
                  urgency. Optimize your marketing efforts with precision,
                  captivating visitors and turning them into loyal customers.
                  Unlock your business potential with the transformative impact
                  of digital marketing.”
                </p>
                </div>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col md={6}>
              <div className="content">
                <h3>Organic Leads: </h3>

                <p className="menu">
                  Generate sustainable business growth through organic lead
                  generation strategies.
                </p>

                <ul className="menu">
                  <li>Create high-quality and relevant content. </li>
                  <li>Optimize your website and content for search engines.</li>
                  <li>
                    Engage with your target audience on social media platforms.
                  </li>
                  <li>
                    Offer valuable lead magnets and use opt-in forms to capture
                    leads.{" "}
                  </li>
                  <li>Implement email marketing campaigns to nurture leads.</li>
                  <li>
                    Encourage referrals from existing clients or contacts{" "}
                  </li>
                  <li>
                    Regularly analyze and optimize your lead generation efforts.
                  </li>
                </ul>
                <p className="menu">
                  “Tap into the power of cost leadership, captivating visuals,
                  and strategic website design to create an irresistible online
                  presence. Drive action and maximize your business potential by
                  avoiding decision paralysis and leveraging the psychology of
                  loss aversion. Unlock your website’s true potential and fuel
                  your business success.”
                </p>
              </div>
              </Col>
              <Col md={6}>
                <img className="img-responsive" src="./Images/organic.jpg" />
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col md={6}>
                <img className="img-responsive" src="./Images/naw.png" />
              </Col>
              <Col md={6}>
              <div className="content">
                <h3>Leads Filtering Process: </h3>

                <p className="menu">
                  Maximize conversions by focusing on relevant sales
                  opportunities through leads filtering.
                </p>

                <ul className="menu">
                  <li> Define your target audience. </li>
                  <li>Establish lead qualification criteria. .</li>
                  <li>Use lead scoring to prioritize leads.</li>
                  <li>Analyze past conversion data.</li>
                  <li>Utilize automation and technology</li>
                  <li>
                    Foster collaboration between sales and marketing teams.
                  </li>
                  <li>
                    Regularly review and optimize your lead filtering process.
                  </li>
                </ul>
                <p className="menu">
                  “Unlock the power of cost leadership, captivating visuals, and
                  psychological triggers to create a website that engages,
                  converts, and boosts your business.” “Utilize cost-effective
                  strategies, visually appealing design, and psychological
                  tactics to create a website that drives conversions and
                  amplifies your business impact.”
                </p>
                </div>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col md={6}> <div className="content">
                <h3>Neuromarketing: </h3>

                <p className="menu">
                  Utilize neuromarketing techniques to create compelling
                  campaigns that resonate with your audience.
                </p>

                <ul className="menu">
                  <li>
                    {" "}
                    Neuromarketing taps into subconscious decision-making
                    processes.{" "}
                  </li>
                  <li>
                    Emotions play a significant role in consumer behavior. .
                  </li>
                  <li>
                    Attention and engagement are crucial for effective
                    marketing.
                  </li>
                  <li>
                    Neuromarketing helps understand brand perception and
                    associations.
                  </li>
                  <li>
                    Pricing and decision-making can be optimized through
                    neuromarketing.{" "}
                  </li>
                  <li>
                    User experience and product design can be enhanced using
                    neuromarketing insights.
                  </li>
                  <li>
                    Cross-cultural studies offer valuable insights for
                    international markets.
                  </li>
                  <li>
                    Ethical considerations must be taken into account when using
                    neuromarketing techniques.{" "}
                  </li>
                </ul>
                <p className="menu">
                  “Tap into the power of neuromarketing to drive your business
                  forward. Understand consumer emotions, create visually
                  engaging content, and overcome decision hurdles. With a
                  captivating website that inspires action, you’ll maximize
                  conversions and unlock your business’s true potential. Let
                  neuromarketing be your secret weapon to connect with customers
                  and make a lasting impact.”</p>
                </div>
              </Col>
              <Col md={6}>
                <img className="img-responsive" src="./Images/neuro.png" />
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col md={6}>
              <div className="content">
                <h3>Bulk WhatsApp Marketing: </h3>

                <p className="menu">
                  Reach a wider audience and drive engagement through bulk
                  WhatsApp marketing.
                </p>

                <ul className="menu">
                  <li>Direct and personalized communication </li>
                  <li>High open and engagement rates </li>
                  <li>Cost-effective marketing</li>
                  <li>Instant delivery and real-time communication</li>
                  <li>Targeted and segmented campaigns </li>
                  <li>Rich media content</li>
                  <li>Increased customer engagement and loyalty</li>
                  <li>Customer support and assistance </li>
                  <li>Measurable results and analytics </li>
                  <li>Compliance and privacy</li>
                </ul>
                <p>
                  Supercharge your business with bulk WhatsApp marketing.
                  Harness cost-effective strategies, captivating visuals,
                  FOMO-inducing effects, targeted campaigns, and confident
                  decision-making. Connect with customers personally, drive
                  conversions, and unlock new opportunities. Maximize your
                  marketing potential, stay ahead of the competition, and make a
                  lasting impact in the digital landscape.
                </p>
                </div>
              </Col>
              <Col md={6}>
                <img className="img-responsive" src="./Images/bulk.png" />
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col md={6}>
                <img className="img-responsive" src="./Images/social.jpg" />
              </Col>
              <Col md={6}>
              <div className="content">
                <h3>Professional social media Posting: </h3>

                <p className="menu">
                  Build your brand and connect with your target market through
                  professional social media postings.
                </p>

                <ul className="menu">
                  <li> Craft clear and compelling messages. </li>
                  <li>Use visually appealing content. </li>
                  <li>Maintain consistent branding.</li>
                  <li>Encourage engagement and interaction.</li>
                  <li>Provide valuable content. </li>
                  <li>Include effective call-to-action (CTA).</li>
                  <li>Utilize relevant hashtags.</li>
                  <li>Analyze post performance and adjust accordingly. </li>
                </ul>
                <p className="menu">
                  “Tap into the power of social media to unlock your business
                  potential. Harness cost leadership, captivating visuals, and
                  irresistible FOMO-inducing effects. Navigate decision
                  paralysis and maximize conversions. Step into the captivating
                  realm of social media marketing and drive your success
                  forward.”
                </p>
                </div>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col md={6}>
                <img
                  classname="img-responsive"
                  src="./Images/video.png"
                  alt="Video Promotion"
                />
              </Col>
              <Col md={6}>
              <div className="content">
                <h3>Video Promotion: </h3>

                <p className="menu">
                  Capture attention and convey your message effectively with
                  captivating video promotions
                </p>

                <ul className="menu">
                  <li>Engaging and memorable content </li>
                  <li>Increased brand visibility and recognition </li>
                  <li>Enhanced audience engagement and interaction</li>
                  <li>Effective storytelling and emotional connection</li>
                  <li>Wide reach and potential for viral sharing </li>
                  <li>Boost in website traffic and lead generation</li>
                  <li>Improved search engine visibility and rankings</li>
                  <li>Versatile and shareable across various platforms </li>
                  <li>Measurable results and analytics</li>
                  <li>
                    Opportunity to showcase products, services, and brand
                    personality.{" "}
                  </li>
                </ul>
                <p className="menu">
                  Elevate your business with video promotion. Engage your
                  audience, convert prospects, and unlock your full potential.
                  Harness the power of captivating visuals, create a sense of
                  urgency, and guide your customers towards action. Say goodbye
                  to decision doubts and maximize your business impact.
                  Experience the simplicity and effectiveness of video marketing
                  today.
                </p>
                <p className="menu">
                  Contact us today at +91- 9415174046 or info@gravitonweb.com to
                  discuss how our services can benefit your business. We look
                  forward to helping you achieve your goals.{" "}
                </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      {/* end */}
  
 
      </>
  );
};



export default ServicesListing