import React from 'react';
import './Servicecategory.css';
import { FaCode, FaPalette, FaMobileAlt, FaChartLine, FaPaintBrush, FaCube } from 'react-icons/fa';
import { Container, Row, Col } from 'react-bootstrap';

const ServicesCategory = () => {

  const sections = [
    {
      title: 'Web Design',
      icon: <FaCode />,
      description: 'Create stunning and responsive web designs.'
    },
    {
      title: 'UI/UX Design',
      icon: <FaPalette />,
      description: 'Craft intuitive and user-friendly interfaces.'
    },
    {
      title: 'App Design',
      icon: <FaMobileAlt />,
      description: 'Design engaging and functional mobile apps.'
    },
    {
      title: 'SEO Expert',
      icon: <FaChartLine />,
      description: 'Boost your online presence with effective SEO strategies.'
    },
    {
      title: 'Graphic Design',
      icon: <FaPaintBrush />,
      description: 'Design visually appealing graphics and illustrations.'
    },
    {
      title: 'Product Design',
      icon: <FaCube />,
      description: 'Innovate and create successful product designs.'
    },
  ];

  return (
    <Container className="service-category-page text-center">
      <h1 className="services-header">Our Services</h1>
      <Row className="sections">
        {sections.map((section, index) => (
          <Col md={4} key={index}>
            <div className="section">
              <div className="icon">{section.icon}</div>
              <h2>{section.title}</h2>
              <p>{section.description}</p>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default ServicesCategory
