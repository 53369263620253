import React from "react";
import { Col, Row,Carousel } from "antd";
import { Button} from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './home.css';
import Image1 from './imgs/homebanner1.png';
import Image2 from './imgs/homebanner2.png';
import Image3 from './imgs/homebanner3.jpg';
import About1 from './imgs/about1.jpg';
import About2 from './imgs/about2.jpg';
import About3 from './imgs/about3.jpg';
import Feature from './imgs/features1.png';
import Service1 from './imgs/services1.jpg';
import Service2 from './imgs/services2.jpg';
import Service3 from './imgs/services3.jpg';
import Slider1 from './imgs/slider1.jpg';
import Slider2 from './imgs/slider2.jpg';
import Slider3 from './imgs/slider3.jpg';
import Slider4 from './imgs/slider4.jpg';



const slides = [
  {
    heading1: "Digital Marketing",
    description1: "We work with international startups, help",
    description2: "entrepreneurs launch for the long term.",
    button1Text: "GET STARTED",
    button2Text: "SEE DETAIL",
    image: Image1,
  },
  {
    heading1: "Software Development",
    description1: "We work with international startups, help",
    description2: "entrepreneurs launch for the long term.",
    button1Text: "GET STARTED",
    button2Text: "SEE DETAIL",
    image: Image2,
  },
  {
    heading1: "Digital Currency",
    description1: "We work with international startups, help",
    description2: "entrepreneurs launch for the long term.",
    button1Text: "GET STARTED",
    button2Text: "SEE DETAIL",
    image: Image3,
  },
];
const Home = () => {

    const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1, 
    slidesToScroll: 1,
    autoplay: true, 
    autoplaySpeed: 3000,
  };
 return (
    <>
    <Carousel
      effect="fade"
      autoplay
      className="combined-carousel"
      dots={false}
    >
      {slides.map((slide, index) => (
        <div key={index} className="combined-slide">
          <div className="container">
            <Row className='content-part d-flex align-items-center'>
              <Col lg={12} md={12} sm={24}>
                <h2>{slide.heading1}</h2>
                <h5 className="content-heading mt-4">
                  {slide.description1}
                </h5>
                <h5 className="content-heading mb-4">
                  {slide.description2}
                </h5>
                <Link href="#"><Button className="btn-primary1">
                  {slide.button1Text}
                </Button></Link>
                <Link href="#"><Button className="btn-primary2">
                  {slide.button2Text}
                </Button></Link>
              </Col>
              <Col lg={12} md={12} sm={24}>
                <div className="image">
                  <img
                    src={slide.image}
                    className="responsive-image"
                    alt={`Slide ${index + 1}`}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      ))}
    </Carousel>

    <div className="container">
        <Row className="content-part1">
          <Col xl={12} lg={12} md={24} sm={24}>
          <div className="puzzle">
          <div className='deco-1'></div>
          <div className='deco-2'></div>
          <figure className='img1 big'>
          <img src={About1}/>
          </figure>
          <figure className='img1 medium'>
          <img src={About2}/>
          </figure>
          <figure className='img1 small'>
          <img src={About3}/>
          </figure>
         <div className='deco-3'></div>
         <div className='deco-4'></div>
         <div className='deco-5'></div>
        </div>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24}>
            <h3>Through our large capacity we dare to</h3>
            <h3>say we are different.</h3>
            <p className="paragraph1">Vestibulum sit amet tortor libero lobortis semper at et odio. In eu tellus tellus.
            <br></br> Pellentesque ullamcorper ultrices. Aenean facilisis 
            vitae purus <br></br>facilisis semper.</p>
            <Row>
              <Col lg={8} md={8} sm={8} xs={8}>
              <h3 className="horizontal-line1">+200</h3>
              <p className="para-subtitle">employee</p>
              </Col>
              <Col lg={8} md={8} sm={8} xs={8}>
              <h3 className="horizontal-line2">+500</h3>
              <p className="para-subtitle">projects</p>
              </Col>
              <Col lg={8} md={8} sm={8} xs={8}>
              <h3 className="horizontal-line1">+300</h3>
              <p className="para-subtitle">clients</p>
              </Col>
            </Row>
          </Col>
        </Row>
    </div>

    <div className="container">
      <Row className="content-part2">
        <Col xl={12} lg={12} md={24} sm={24}>
        <div className="image">
              <img
                src={Feature}
                className="responsive-image"
              />
        </div>
        </Col>
        <Col xl={12} lg={12} md={24} sm={24}>
         <h3 className="about-heading">About Us</h3>
         <hr className="about-horizontal-line"></hr>
         <p className="paragraph2">Our mission is to diversify the tech industry 
         through accessible<br></br>education and apprenticeship, 
         unlocking the door to opportunity<br></br> and empowering 
         people to achieve their dreams.</p>
        <Link href="#"><Button className="btn-primary3">JOIN NOW</Button></Link>
        </Col>
      </Row>
    </div>

    <div className="container-fluid content-part3"style={{ overflowX: 'hidden'}}>
     <h2 className="text-center services-heading">Services</h2>
     <hr className="services-horizontal-line"></hr>
     <Row className="d-flex justify-content-center content-part3" gutter={[40, 40]}>
      <Col  xl={6} lg={6} md={8} sm={12}>
        <div className="services-card">
          <figure>
          <img src={Service1}/>
          </figure>
          <div className="services-content">
          <h5>Software Development</h5>
          <p className="services-paragraph">
           Proin ac arcu nisl. Duis eu molestie lectus. Nam quis mauris 
          faucibus, aliquet elit eu, rhoncus ipsum.
         </p>
         <Link href="#"><Button className="services-btn">See Details</Button></Link>
          </div> 
        </div>
      </Col>
      <Col  xl={6} lg={6} md={8} sm={12}>
        <div className="services-card">
          <figure>
          <img src={Service2}/>
          </figure>
          <div className="services-content">
          <h5>Andriod Development</h5>
          <p className="services-paragraph">
           Proin ac arcu nisl. Duis eu molestie lectus. Nam quis mauris 
          faucibus, aliquet elit eu, rhoncus ipsum.
         </p>
         <Link href="#"><Button className="services-btn">See Details</Button></Link>
          </div> 
        </div>
      </Col>
      <Col  xl={6} lg={6} md={8} sm={12}>
        <div className="services-card">
          <figure>
          <img src={Service3}/>
          </figure>
          <div className="services-content">
          <h5>Neuromaketing</h5>
          <p className="services-paragraph">
           Proin ac arcu nisl. Duis eu molestie lectus. Nam quis mauris 
          faucibus, aliquet elit eu, rhoncus ipsum.
         </p>
         <Link href="#"><Button className="services-btn">See Details</Button></Link>
          </div> 
        </div>
      </Col>
     
     </Row>
     <Row className="d-flex justify-content-center content-part3" gutter={[40, 40]}>
      <Col  xl={6} lg={6} md={8} sm={12}>
        <div className="services-card">
          <figure>
          <img src={Service2}/>
          </figure>
          <div className="services-content">
          <h5>Digital marketing</h5>
          <p className="services-paragraph">
           Proin ac arcu nisl. Duis eu molestie lectus. Nam quis mauris 
          faucibus, aliquet elit eu, rhoncus ipsum.
         </p>
         <Link href="#"><Button className="services-btn">See Details</Button></Link>
          </div> 
        </div>
      </Col>
      <Col  xl={6} lg={6} md={8} sm={12}>
        <div className="services-card">
          <figure>
          <img src={Service3}/>
          </figure>
          <div className="services-content">
          <h5>Webtalent gravity</h5>
          <p className="services-paragraph">
           Proin ac arcu nisl. Duis eu molestie lectus. Nam quis mauris 
          faucibus, aliquet elit eu, rhoncus ipsum.
         </p>
         <Link href="#"><Button className="services-btn">See Details</Button></Link>
          </div> 
        </div>
      </Col>
      <Col  xl={6} lg={6} md={8} sm={12}>
        <div className="services-card">
          <figure>
          <img src={Service1}/>
          </figure>
          <div className="services-content">
          <h5>Grow your business</h5>
          <p className="services-paragraph">
           Proin ac arcu nisl. Duis eu molestie lectus. Nam quis mauris 
          faucibus, aliquet elit eu, rhoncus ipsum.
         </p>
         <Link href="#"><Button className="services-btn">See Details</Button></Link>
          </div> 
        </div>
      </Col>
     
     </Row>
    
    </div>

    <div className=" container content-part3 testimonial-part">
      <h3 className="text-center testimonial-heading">Client Testimonials</h3>
      <hr className="testimonials-horizontal-line"></hr>
      <h4 className="text-center mt-4">They are doing great things with us.</h4>
      <Row className="d-flex justify-content-center">
        <Col lg={18} md={24} sm={24}>
          <Slider {...settings}>
  
             <div className="testimonial">
              <div className="paper-card">
                <div className="avatar">
                  <img src={Slider1} alt="img1"></img>
                </div>
                <h5 className="slider-caption">Jovelin Doe</h5>
                <p className="slider-para">Frontend Designer</p>
                <p className="slider-para1">Cras convallis lacus orci, 
                tristique tincidunt magna consequat in. In vel 
                pulvinar est, at euismod libero.</p>
              </div>
             </div>
        

             <div className="testimonial">
              <div className="paper-card">
                <div className="avatar">
                  <img src={Slider2} alt="img1"></img>
                </div>
                <h5 className="slider-caption">Jihan Doe</h5>
                <p className="slider-para">Graphic Designer</p>
                <p className="slider-para1">Cras convallis lacus orci, 
                tristique tincidunt magna consequat in. In vel 
                pulvinar est, at euismod libero.</p>
              </div>
             </div>

             <div className="testimonial">
              <div className="paper-card">
                <div className="avatar">
                  <img src={Slider3} alt="img1"></img>
                </div>
                <h5 className="slider-caption">Jean Doe</h5>
                <p className="slider-para">Backend Designer</p>
                <p className="slider-para1">Cras convallis lacus orci, 
                tristique tincidunt magna consequat in. In vel 
                pulvinar est, at euismod libero.</p>
              </div>
             </div>

             <div className="testimonial">
              <div className="paper-card">
                <div className="avatar">
                  <img src={Slider4} alt="img1"></img>
                </div>
                <h5 className="slider-caption">John Doe</h5>
                <p className="slider-para">Fullstack Designer</p>
                <p className="slider-para1">Cras convallis lacus orci, 
                tristique tincidunt magna consequat in. In vel 
                pulvinar est, at euismod libero.</p>
              </div>
             </div>
          </Slider>
        </Col>
      </Row>
    </div>

    <div className="container-fluid content-part3">
     <Row className="d-flex justify-content-center">
      <Col lg={18} md={24} sm={24}>
      <section>
        <div className="get-in-touch">
          <div className="get-in-touch1">
           <Row>
            <Col lg={12} md={12} sm={24}>
              <h3>Ready to get started ?</h3>
              <h5 className="mb-0 mt-4">
               Best Tech team are not instant born, they're made...
              </h5>
            </Col>
            <Col lg={12} md={12} sm={24} className="d-flex align-items-center justify-content-center mt-3">
            
            <Link href="#"><Button className="get-in-touch-btn">Join Now!</Button></Link>
            
            </Col>

           </Row>
          </div>
         
        </div>
      </section>
      </Col>
     </Row>

    </div>

    
    </>
  )
}

export default Home;