import React from "react";
import { NavLink } from "react-router-dom";
import { Navbar, NavbarBrand, NavDropdown, Dropdown } from "react-bootstrap";
import "./style.css";

const NavBar = () => {
  return (
    <>
      <div className="header sticky-top">
        <Navbar className="navbar" expand="lg navbar-light">
          <div className="container">
            <NavbarBrand className="navbar-brand" to="/">
              NeuroWebGravity
            </NavbarBrand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <NavLink className="nav-link" aria-current="page" to="/">
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/About">
                    About
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/Contact">
                    Contact
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/ServicesListing">
                    Services Listing
                  </NavLink>
                </li>
                {/* <li className="nav-item">
                  <NavLink className="nav-link" to="/TechnicalDevelopment">
                    Services Category
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/ServicesDetail">
                    Services Detail
                  </NavLink>
                </li> */}
                <li className="nav-item">
                  <NavLink className="nav-link" to="/ServicesCategory">
                    Services Category
                  </NavLink>
                </li>
                <li className="nav-item dropdown">
                  <NavLink
                    className="nav-link dropdown-toggle"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  ></NavLink>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <li>
                      <NavLink
                        className="dropdown-item"
                        to="/TechnicalDevelopment"
                      >
                        Technical Development
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className="dropdown-item"
                        to="/BussinessDevelopment"
                      >
                        Bussiness Development
                      </NavLink>
                    </li>
                     {" "}
                  </ul>
                </li>
                 
                <li className="nav-item">
                  <NavLink className="nav-link" to="/ServicesDetail">
                    Services Detail
                  </NavLink>
                </li>
              </ul>
            </Navbar.Collapse>
          </div>
        </Navbar>
      </div>
    </>
  );
};

export default NavBar;
