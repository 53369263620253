import React from "react";
import myImage from "./Images/about_2d@2x.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./About.css";
const About = () => {
  return (
    <>
      <h2 className="about-text ">About Us</h2>
      <Container>
        <Row className="mt-5">
          <Col md={6}>
            <img src={myImage} alt="My Image" />
          </Col>

          <Col md={6}>
            <h2>Through our large capacity we dare to say we are different.</h2>
            <p className="mt-4">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam,
              officiis ipsum aut a minus voluptate quaerat sed distinctio eaque
              magni laborum tenetur quisquam sapiente repellendus harum! Minus
              ea porro aliquid!
            </p>
            <Row>
              <Col xs={4}>
                <h1 style={{ textAlign: "start" }}>+100</h1>
                <hr className="statusbar1"></hr>
                <h3>EMPLOYEE</h3>
              </Col>
              <Col xs={4}>
                <h1 style={{ textAlign: "start" }}>+200</h1>
                <hr className="statusbar2"></hr>
                <h3>PROJECTS</h3>
              </Col>

              <Col xs={4}>
                <h1 style={{ textAlign: "start" }}>+500</h1>
                <hr className="statusbar1"></hr>
                <h3>CLIENTS</h3>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default About;
