import React, { useEffect, useState } from "react";
import "./Contact.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


import {
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaEnvelope,
  FaFax,
} from "react-icons/fa";


const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    phoneNumber: "",
  });
  const [submissionStatus, setSubmissionStatus] = useState("");

  const validateForm = () => {
    const errors = {};

    if (!name) errors.name = "Name is required";
    if (!email) errors.email = "Email is required";
    if (!phoneNumber) errors.phoneNumber = "Phone is required";

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      // Handle form submission
      console.log("Form submitted:", name, email, phoneNumber, message);
      setSubmissionStatus("Message sent successfully!");

      setName("");
      setEmail("");
      setPhoneNumber("");
      setMessage("");
    }
  };
  useEffect(() => {
    if (submissionStatus) {
      const timer = setTimeout(() => {
        setSubmissionStatus("");
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [submissionStatus]);

  return (
    <>
    <div className="contact-container mt-5">
      <div className="contact-form">
        <h2 className="heading mb-4"><b>Contact Us</b></h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={name}
            placeholder="Your Name *"
            onChange={(e) => setName(e.target.value)}
            required
            
          />
          <input
            type="email"
            value={email}
            placeholder="Your Email *"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="tel"
            value={phoneNumber}
            placeholder="Phone Number"
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <textarea
            value={message}
            placeholder="Your Message *"
            onChange={(e) => setMessage(e.target.value)}
            required
            
          ></textarea>
         

          <button type="submit" style={{ marginTop: "30px" }} className="contact-button">
            Submit
          </button>
          {submissionStatus && (
            <p className="submission-status">{submissionStatus}</p>
          )}
        </form>
      </div>
      <div className="boxes">
        <div className="contact-box">
          <FaMapMarkerAlt className="icon" /><b>Location</b>
          <p>123 Main Street, City, Country</p>
        </div>
        <div className="contact-box">
          <FaPhoneAlt className="icon" /><b>Phone</b>
          <p>+123 456 7890</p>
        </div>
        <div className="contact-box">
          <FaFax className="icon" /><b>Fax</b>
          <p>ABC-1234</p>
        </div>
        <div className="contact-box">   
          <FaEnvelope className="icon" /><b>Email</b>
          <p>info@example.com</p>
        </div>
         
      </div>
    </div>

      <div className="map-container mt-5"  style={{  padding: "20px", width: "calc(100% )"}}>
         <iframe
           title="Google Map Location"
           src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.394061055057!2d77.6059257143611!3d12.912998991445633!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae163f87ab036f%3A0x25c08a82c50f4a3f!2sTavarekere%2C%20Aicobo%20Nagar%2C%20BTM%201st%20Stage%2C%20Bengaluru%2C%20Karnataka%20560029%2C%20India!5e0!3m2!1sen!2sus!4v1625843830043!5m2!1sen!2sus"
           frameBorder="0"
           style={{ border: 0, width: "100%", height: "calc(100vh - 300px)"}}
           allowFullScreen
         ></iframe>
      </div>
    </>
    
  );
};

export default Contact;
