import logo from './logo.svg';
import './App.css';
import Home from './Modules/Portfolio/Components/Home';
import About from './Modules/Portfolio/Components/About';
import Contact from './Modules/Portfolio/Components/Contact';
import NavBar from './Common/NavBar';
import {  Route, Routes } from 'react-router-dom';
import ServicesDetail from './Modules/Portfolio/Components/ServicesDetail';
import ServicesCategory from './Modules/Portfolio/Components/ServicesCategory';
import ServicesListing from './Modules/Portfolio/Components/ServicesListing';
import FooterPart from './Common/FooterPart';
import TechnicalDevelopment from './Modules/Portfolio/Components/TechnicalDevelopment';
import BussinessDevelopment from './Modules/Portfolio/Components/BussinessDevelopment';

function App() {
  return (
    <>
    <div className="App">
     
      <NavBar/>
      <Routes>
      <Route path="/"  element={<Home/>}/>
      <Route path="/About" element={<About/>}/>
      <Route path="/Contact"  element={<Contact/>}/>
      <Route path="/ServicesListing"  element={<ServicesListing/>}/>
      <Route path="/ServicesCategory"  element={<ServicesCategory/>}/>
      <Route path="/ServicesDetail"  element={<ServicesDetail/>}/>
      <Route path="/TechnicalDevelopment"  element={<TechnicalDevelopment/>}/>
      <Route path="/BussinessDevelopment"  element={< BussinessDevelopment/>}/>
      {/* <h1>service</h1> */}
      {/* <h1>Neur</h1> */}
    </Routes>
    
    <FooterPart/>
    </div>
    </>
  );
}

export default App;
