import React, { useEffect } from 'react'
import "./TechnicalDevelopmet.css";
import { Container, Row, Col, Card } from "react-bootstrap";

import Image1 from "./Images/userfriendly.jpg";
import Image2 from "./Images/payment.jpg";
import Image3 from "./Images/android.jpg";
import Image4 from "./Images/chat1.png";
import Image5 from "./Images/domain1.png";
import Image6 from "./Images/webhoisting.png";
import Image7 from "./Images/Visiting.png";

const TechnicalDevelopment = () => {
 



  const steps = [
    "Plan",
    "Build",
    "Code",
    "Test",
    "Release",
    "Deploy",
    "Operate",
    "Monitor",
  ];
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate");
          } else {
            entry.target.classList.remove("animate");
          }
        });
      },
      { threshold: 0.8 }
    ); 
    const cards = document.querySelectorAll(".service-card");
    cards.forEach((card) => {
      observer.observe(card);
    });
    const boxes = document.querySelectorAll(".box");
    boxes.forEach((box) => {
      observer.observe(box);
    });

    return () => {
      
      observer.disconnect();
    };
  }, []);
  return (
  <>
    <div className="service-category-page"></div>
      <div className="first-container">
        <h1 className="services-header mt-5">Technical Development</h1>
        <p className="intro-text mt-3">
          We specialize in providing cutting-edge technical solutions to help
          your business thrive in the digital world. Our team of skilled
          developers and experts work together to create innovative solutions
          that address your unique challenges.
        </p>
        <Container>
          <Row className="sections mt-5">
            <Col md={4} xs={12}>
              <Card className="service-card">
                <Card.Body>
                  <Card.Title className="card-title">
                    <img
                      src={Image1}
                      style={{ height: "200px" }}
                      alt="My Image"
                    />
                    <br /> user freindly websites
                  </Card.Title>
                  <Card.Text >
                    Custom web development solutions to elevate your online
                    presence.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={4} xs={12}>
              <Card className="service-card">
                <Card.Body>
                  <Card.Title className="card-title">
                    <img
                      src={Image2}
                      style={{ height: "200px" }}
                      alt="My Image"
                    />
                    <br /> Online Payments
                  </Card.Title>
                  <Card.Text>
                    Custom web development solutions to elevate your online
                    presence.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} xs={12}>
              <Card className="service-card">
                <Card.Body>
                  <Card.Title className="card-title">
                    <img
                      src={Image3}
                      style={{ height: "200px" }}
                      alt="My Image"
                    />
                    <br /> Android App
                  </Card.Title>
                  <Card.Text>
                    Custom web development solutions to elevate your online
                    presence.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* <div className="between-image"></div> */}

      <div className="second-container">
        <h1 className="service-header">
          <b>What We Do?</b>
        </h1>
        <Container>
          
          <Row className="sections1">
        
            <Col lg={3}></Col>
          
            <Col lg={4} md={6} xs={12}>
         
              <Card className="service-card">
             
                <Card.Body>
                  <Card.Title className="card-title">
                    <img
                      src={Image4}
                      style={{ height: "200px" }}
                      alt="My Image"
                    />
                    <br /> Chat support
                  </Card.Title>
                  <Card.Text>
                    Custom web development solutions to elevate your online
                    presence.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col lg={4} md={6} xs={12}>
              <Card className="service-card">
                <Card.Body>
                  <Card.Title className="card-title">
                    <img
                      src={Image5}
                      style={{ height: "200px" }}
                      alt="My Image"
                    />
                    <br /> Domain Name
                  </Card.Title>
                  <Card.Text>
                    Custom web development solutions to elevate your online
                    presence.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={1}></Col>
           
          </Row>
          <Row className="sections1 mt-4">
        
            <Col lg={3}></Col>
            <Col lg={4} md={6} xs={12}>
       
              <Card className="service-card">
         
                <Card.Body>
                  <Card.Title className="card-title">
                    <img
                      src={Image6}
                      style={{ height: "200px" }}
                      alt="My Image"
                    />
                    <br /> Reliable Hosting Services
                  </Card.Title>
                  <Card.Text>
                    Custom web development solutions to elevate your online
                    presence.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={4} xs={12}>
              <Card className="service-card">
                <Card.Body>
                  <Card.Title className="card-title">
                    <img
                      src={Image7}
                      style={{ height: "200px" }}
                      alt="My Image"
                    />
                    <br /> Visiting Rate Tracking
                  </Card.Title>
                  <Card.Text>
                    Custom web development solutions to elevate your online
                    presence.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          
          </Row>
        </Container>
      </div>

      <div className="third-container">
        <h1>
          <b>Our Values</b>
        </h1>
        <Container>
          <Row className="sections">
            <Col md={4} xs={12}>
              <Card className="service-card">
                <Card.Body>
                  <Card.Title className="card-title">
                   
                    Quality
                  </Card.Title>
                  <Card.Text>
                  We are serious about the quality of Software Products and Predictability, we do QA and design review at every stage. 
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={4} xs={12}>
              <Card className="service-card">
                <Card.Body>
                  <Card.Title className="card-title">
                 
                    Continuity
                  </Card.Title>
                  <Card.Text>
                  We deliver Software Products to our customers since 2023 and  our team and competencies are expanding from year to year.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} xs={12}>
              <Card className="service-card">
                <Card.Body>
                  <Card.Title className="card-title">
               
                    Transparency
                  </Card.Title>
                  <Card.Text>
                  We believe that Teamwork, Transparency, and Communication with our customers are the right way to best software product building.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="fourth-container">
  <h1>
    <b>How It Works?</b>
  </h1>
  <Container>
    <Row className="sections">
      {steps.map((step, index) => (
        <Col md={3} key={index}>
          <div className="box">
            <div className="count">{index + 1}</div>
            <h3>{step}</h3>
          </div>
        </Col>
      ))}
    </Row>
  </Container>
</div>
  </>
  )
}

export default TechnicalDevelopment